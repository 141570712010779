import { IUser } from "@/data-types";
import { HTTP } from "@/utils/http-common";

const state = {
  status: "",
  message: "",
  user: {} as IUser,
  sessionExpired: false,
};

const mutations = {
  AUTH_REQUEST(authState: any) {
    authState.status = "loading";
  },
  AUTH_SUCCESS(authState: any) {
    authState.status = "success";
  },
  AUTH_ERROR(authState: any, payload: any) {
    authState.status = "error";
    authState.message = payload.message;
  },
  SAVE_USER_DATA(authState: any, payload: IUser) {
    authState.user = payload;
  },
  LOGOUT(authState: any) {
    authState.status = "";
    authState.message = "";
    authState.user = {} as IUser;
  },
  SET_SESSION_EXPIRED(authState: any, payload: boolean) {
    authState.sessionExpired = payload;
  },
};

const actions = {
  login({ commit, dispatch }: any, user: any) {
    return new Promise((resolve, reject) => {
      commit("AUTH_REQUEST");

      const bodyFormData = new FormData();
      bodyFormData.append("email", user.email);
      bodyFormData.append("password", user.password);

      HTTP.post("/login", bodyFormData)
        .then((response) => {
          if (!response.data.two_factor) {
            dispatch("saveUserData").then(() => {
              commit("AUTH_SUCCESS");
              resolve(response);
            });
          } else {
            resolve(response);
          }
          commit("SET_SESSION_EXPIRED", false);
        })
        .catch((error) => {
          commit("AUTH_ERROR", error);
          reject(error);
        });
    });
  },
  saveUserData({ commit, dispatch }: any) {
    return new Promise((resolve, reject) => {
      HTTP.get("/api/user/me")
        .then((response) => {
          const user: IUser = response.data.data;
          commit("SAVE_USER_DATA", user);

          if (user.companies.length === 1) {
            dispatch("getCompanyData", user.companies[0].id).then(() => {
              resolve(response);
              commit("SET_LOADED", true);
            });
          } else {
            commit("SET_LOADED", true);
            resolve(response);
          }
        })
        .catch((error) => {
          resolve(error);
          // window.location.href = window.location.origin + '/login'
          // window.location.href = 'https://master.d2bytsh5e0u49a.amplifyapp.com/';
        });
    });
  },
  logout({ commit }: any) {
    return new Promise((resolve, reject) => {
      commit("LOGOUT");

      HTTP.post("logout")
        .then((response) => {
          localStorage.removeItem("active-company");
          localStorage.removeItem("active-edition");
          commit("LOGOUT");
          resolve(response);
        })
        .catch((error) => {
          localStorage.removeItem("active-company");
          localStorage.removeItem("active-edition");
          commit("AUTH_ERROR", error);
          reject(error);
        });
    });
  },
  checkSessionState({ commit }: any) {
    HTTP.get("/api/user/me")
      .then((response) => {
        if (response.statusText === "OK") {
          commit("SET_SESSION_EXPIRED", false);
        }
      })
      .catch((error) => {
        if (error.request.status === 401) {
          commit("SET_SESSION_EXPIRED", true);
          commit("LOGOUT");
        }
      });
  },
};

const getters = {
  userRole: (authState: any) => {
    return authState.user.role ? authState.user.role : "guest";
  },
  twoFactor: (authState: any) => {
    return authState.user.two_factor ?? false;
  },
  companiesList: (authState: any) => {
    return authState.user.companies ? authState.user.companies : null;
  },
  companiesCount: (authState: any) => {
    return authState.user.companies ? authState.user.companies.length : 0;
  },
  sessionExpired: (authState: any) => {
    return authState.sessionExpired;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
