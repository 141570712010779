import { IEdition } from "@/data-types";
import { HTTP } from "@/utils/http-common";

const state = {
  editions: [] as IEdition[],
  activeEditionId: 0,
};

const mutations = {
  SAVE_EDITIONS_DATA(editionsState: any, payload: any) {
    editionsState.editions = payload;
  },
  SAVE_ACTIVE_EDITION(editionsState: any, payload: any) {
    editionsState.activeEditionId = payload;
  },
};

const actions = {
  getEditionsData({ commit }: any) {
    return new Promise((resolve, reject) => {
      HTTP.get(`api/editions`)
        .then((response) => {
          const editionsData: IEdition[] = response.data.data;
          commit("SAVE_EDITIONS_DATA", editionsData);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const getters = {
  editionsList: (editionsState: any) => {
    return editionsState.editions ? editionsState.editions : null;
  },
  visibleEditionsList: (editionsState: any) => {
    return editionsState.editions
      ? editionsState.editions.filter((item: any) => item.visibility === 1)
      : null;
  },
  activeEditionDetails: (editionsState: any) => {
    if (editionsState.editions) {
      const currentEdition = editionsState.editions.filter((obj: any) => {
        return obj.id === editionsState.activeEditionId;
      });

      return currentEdition[0];
    }
  },
  activeEditionOpened: (editionsState: any) => {
    if (editionsState.editions) {
      const currentEdition = editionsState.editions.filter((obj: any) => {
        return obj.id === editionsState.activeEditionId;
      });

      let opened = false;

      if (currentEdition.length > 0) {
        const dateNow = new Date().toISOString().slice(0, 10);
        const activeEditionDateString = new Date(
          currentEdition[0].end_date.toString()
        )
          .toISOString()
          .slice(0, 10);
        opened = dateNow < activeEditionDateString;
      }

      return opened;
    }
  },
  editionsNames: (editionsState: any) => {
    if (editionsState.editions) {
      const namedDetails = {} as any;
      const data = editionsState.editions;

      data.forEach((element: any) => {
        namedDetails[element.id] = element.name;
      });

      return namedDetails;
    }
  },
  activeEdition: (editionsState: any) => {
    return editionsState.activeEditionId ? editionsState.activeEditionId : 0;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
