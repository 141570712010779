
import Vue from "vue";
import { HTTP } from "@/utils/http-common";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "index",
  data(): any {
    return {
      forgottenPassword: false,
      email: "",
      password: "",
      reminderMessage: "Nie pamiętam hasła",
      passwordResetMessage: false,
      code: null,
      mfaActive: false,
      codeError: null,
      loginError: null,
    };
  },
  computed: {
    ...mapGetters(["userRole", "companiesCount", "twoFactor"]),
  },
  mounted() {
    if (this.userRole !== "guest") {
      if (this.userRole === "administrator" && this.twoFactor) {
        this.$router.push("/admin");
      } else if (this.userRole === "administrator" && !this.twoFactor) {
        this.$router.push("/admin/mfa");
      } else {
        this.$router.push("/informacje-organizacyjne");
      }
    }

    HTTP.get("sanctum/csrf-cookie").catch((e) => {
      console.error(e);
    });
  },
  methods: {
    login: function () {
      if (this.forgottenPassword === false) {
        this.loginError = null;
        const email = this.email;
        const password = this.password;
        this.$store
          .dispatch("login", { email, password })
          .then((response: any) => {
            if (response.data.two_factor) {
              this.mfaActive = true;
              return;
            } else {
              if (this.userRole === "administrator") {
                this.$router.push("/admin/mfa");
                return;
              } else {
                this.handleRedirects();
              }
            }
          })
          .catch((error: any) => {
            if (error?.response?.data?.email) {
              this.loginError = error.response.data.email[0];
            } else {
              this.loginError =
                "Wystąpił błąd, spróbuj ponownie za chwilę lub skontaktuj się z nami.";
            }
          });
      }
    },
    handleRedirects() {
      if (this.userRole === "administrator") {
        this.$router.push("/admin");
      } else {
        if (this.companiesCount === 1) {
          this.$router.push("/informacje-organizacyjne");
        } else {
          this.$router.push("/wybor-firmy");
        }
      }
    },
    mfaChallenge() {
      this.codeError = null;
      HTTP.post("two-factor-challenge", {
        code: this.code,
      })
        .then(() => {
          this.$store
            .dispatch("saveUserData")
            .then(() => {
              this.$store.commit("AUTH_SUCCESS");
            })
            .then(() => {
              this.handleRedirects();
            });
        })
        .catch((error) => {
          if (error?.response?.data?.code) {
            this.codeError = error.response.data.code[0];
          } else {
            this.codeError =
              "Wystąpił błąd, spróbuj ponownie za chwilę lub skontaktuj się z nami.";
          }
        });
    },
    remindPassword() {
      HTTP.post(
        "api/user/reset/password",
        {
          email: this.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          this.passwordResetMessage = `Link z nowym hasłem został wysłany na adres email ${this.email}`;
        })
        .catch((error) => {
          this.passwordResetMessage =
            "Coś poszło nie tak, być może nie masz konta w naszym systemie lub wystąpił błąd serwera.";
        });
    },
    showReminder() {
      this.forgottenPassword = !this.forgottenPassword;
      if (this.passwordResetMessage) {
        this.passwordResetMessage = false;
      }
      if (this.reminderMessage === "Nie pamiętam hasła") {
        this.reminderMessage = "Zaloguj się ponownie";
      } else {
        this.reminderMessage = "Nie pamiętam hasła";
      }
    },
  },
});
