const state = {
  itemAdded: '',
  itemEdited: '',
};

const mutations = {
  ADD_ADMIN_CONTENT(adminContentState: any, payload: any) {
    adminContentState.itemAdded = payload;
  },
  EDIT_ADMIN_CONTENT(adminContentState: any, payload: any) {
    adminContentState.itemEdited = payload;
  },
};

const getters = {
  adminContentAdd: (adminContentState: any) => {
    return adminContentState.itemAdded ? adminContentState.itemAdded : null;
  },
  adminContentEdit: (adminContentState: any) => {
    return adminContentState.itemEdited ? adminContentState.itemEdited : null;
  },
};

const actions = {
  addContent(adminContentState: any, payload: any) {
    adminContentState.commit('ADD_ADMIN_CONTENT', payload);
  },
  editContent(adminContentState: any, payload: any) {
    adminContentState.commit('EDIT_ADMIN_CONTENT', payload);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
