const state = {
  editionsIds: [] as number[],
};

const mutations = {
  SAVE_COMPANY_EDITIONS(companyEditionsState: any, payload: any) {
    companyEditionsState.editionsIds = payload;
  },
};

const actions = {
  getCompanyEditions({ commit, rootState, dispatch }: any) {
    const boughtEditions = [] as number[];
    if (rootState.company.company.packages) {
      rootState.company.company.packages.forEach((element: any) => {
        boughtEditions.push(element.edition_id);
      });
    }

    commit("SAVE_COMPANY_EDITIONS", boughtEditions);

    const activeEditionStorage: any = Number(
      localStorage.getItem("active-edition")
    );

    if (
      activeEditionStorage &&
      boughtEditions.includes(activeEditionStorage, 0)
    ) {
      dispatch("setActiveEdition", activeEditionStorage);
    } else {
      dispatch("setActiveEdition", boughtEditions[0]);
    }
  },
  setActiveEdition({ commit }: any, id: any) {
    localStorage.setItem("active-edition", id);
    commit("SAVE_ACTIVE_EDITION", id);
    commit("SAVE_VISUALISATION_URL", "");
  },
};

const getters = {
  companyEditions: (companyEditionsState: any) => {
    return companyEditionsState.editionsIds
      ? companyEditionsState.editionsIds
      : null;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
