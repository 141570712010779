import { ICompany } from '@/data-types';
import { HTTP } from '@/utils/http-common';

const state = {
  company: {} as ICompany,
  guardian: null,
  visualisationUrl: '',
};

const mutations = {
  SAVE_COMPANY_DATA(companyState: any, payload: ICompany) {
    companyState.company = payload;
  },
  SAVE_COMPANY_GUARDIAN(companyState: any, payload: ICompany) {
    companyState.guardian = payload;
  },
  SAVE_VISUALISATION_URL(companyState: any, payload: string) {
    companyState.visualisationUrl = payload;
  },
};

const actions = {
  sendCompanyData({ companyState, dispatch }: any, payload: any) {
    return new Promise((resolve, reject) => {
      const sendData = new FormData();
      payload.name ? sendData.append('name', payload.name) : sendData.append('name', companyState.company.name || '');
      payload.website ? sendData.append('website', payload.website) : sendData.append('website', companyState.company.website || '');
      payload.phone ? sendData.append('phone', payload.phone) : sendData.append('phone', companyState.company.phone || '');
      payload.industry ? sendData.append('industry', payload.industry) : sendData.append('industry', companyState.company.industry || '');
      payload.logo ? sendData.append('logo', payload.logo) : payload = payload;

      HTTP.post(`api/company/${companyState.company.id}`, sendData)
        .then((response: any) => {
          dispatch('getCompanyData', companyState.company.id).then(() => {
            resolve(response);
          });
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
  getCompanyGuardian({ commit, rootGetters }: any, id: string) {
    return new Promise((resolve, reject) => {
      HTTP.get(`api/account-managers`)
        .then((response: any) => {
          const guardianDetailIncompany = rootGetters.namedDetails[`${rootGetters.activeEdition}_ed_guardian`];
          if (guardianDetailIncompany) {
            const companyGuardian = response.data.find((user: any) => {
              return user.id === parseInt(guardianDetailIncompany.value, 10);
            });

            commit('SAVE_COMPANY_GUARDIAN', companyGuardian);
          }

          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
  getCompanyData({ commit, dispatch }: any, id: string) {
    return new Promise((resolve, reject) => {
      HTTP.get(`api/company/${id}`)
        .then((response: any) => {
          const companyData: ICompany = response.data.data;
          commit('SAVE_COMPANY_DATA', companyData);
          localStorage.setItem('active-company', id);

          dispatch('getCompanyGuardian');
          dispatch('getCompanyEditions').then(() => {
            resolve(response);
          });
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
  // changing variable name from state to companyState occured in not getting any local state in this case
  // tslint:disable-next-line
  getVisualisationData({ commit, state, rootState }: any) {
    return new Promise((resolve, reject) => {
      HTTP.get(`api/company/visualisation/${state.company.id}/${rootState.editions.activeEditionId}`)
        .then((response: any) => {
          commit('SAVE_VISUALISATION_URL', response.data.data.message);
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
  // changing variable name from state to companyState occured in not getting any local state in this case
  // tslint:disable-next-line
  exportDataToWord({ state }: any, details: any) {
    return new Promise((resolve, reject) => {
      const sendData = new FormData();
      sendData.append('details', details.keys.join(','));

      HTTP.post(`api/edition/exportWord/${state.company.id}?language=${details.english ? 'en' : 'pl'}`, sendData)
        .then((response: any) => {
          resolve(response.data.data.message);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
};

const getters = {
  companyData: (companyState: any) => {
    return companyState.company ? companyState.company : {} as ICompany;
  },
  companyServices: (companyState: any, rootState: any) => {
    if (companyState.company.services) {
      const currentEditionServices = companyState.company.services.filter((obj: any) => {
        return obj.edition_id === rootState.activeEdition;
      });

      return currentEditionServices;
    }
  },
  visualisationUrl: (companyState: any) => {
    return companyState.visualisationUrl ? companyState.visualisationUrl : null;
  },
  currentCompanyPackage: (companyState: any, rootState: any) => {
    if (companyState.company.packages) {
      const currentPackage = companyState.company.packages.filter((obj: any) => {
        return obj.edition_id === rootState.activeEdition;
      });

      return currentPackage[0];
    }
  },
  namedDetails: (companyState: any) => {
    if (companyState.company.details) {
      const namedDetails = {} as any;
      const data = companyState.company.details;

      data.forEach((element: any) => {
        namedDetails[element.key] = element;
      });

      return namedDetails;
    }
  },
  companyGuardian: (companyState: any) => {
    if (companyState.guardian) {
      return companyState.guardian;
    } else {
      return {
        name: 'Zespół JOBICON',
        email: 'jobicon@pracuj.pl',
        phone: '',
      };
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
