import Vue from 'vue';
import Vuex from 'vuex';
import { HTTP } from '@/utils/http-common';
import companyEditions from './modules/companyEditions';
import adminContent from './modules/adminContent';
import editions from './modules/editions';
import company from './modules/company';
import auth from './modules/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    companyEditions,
    adminContent,
    editions,
    company,
    auth,
  },
  state: {
    loaded: false,
  },
  mutations: {
    SET_LOADED(state, payload) {
      state.loaded = payload;
    },
  },
  actions: {
    copyCompanyDataBetweenEditions({ state, dispatch }: any, payload: any) {
      return new Promise((resolve, reject) => {
        const sendData = new FormData();

        sendData.append('source', payload.source);
        sendData.append(
          'destination',
          state.editions.activeEditionId.toString(),
        );
        sendData.append('keys', payload.keys);

        HTTP.post(`api/company/move/${state.company.company.id}`, sendData)
          .then((response) => {
            dispatch('getCompanyData', state.company.company.id).then(() => {
              resolve(response);
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    sendBulkData({ state, dispatch }: any, payload: any) {
      return new Promise((resolve, reject) => {
        const sendData = new FormData();

        for (const key in payload.data) {
          if (payload.data.hasOwnProperty(key)) {
            sendData.append('key[]', key);
            sendData.append('value[]', (payload.data as any)[key]);
            sendData.append('confirmed[]', payload.confirmed);
          }
        }

        HTTP.post(
          `api/detail/bulk/${state.editions.activeEditionId}/${state.company.company.id}`,
          sendData,
        )
          .then((response) => {
            dispatch('getCompanyData', state.company.company.id).then(() => {
              resolve(response);
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    sendSingleDetail({ state, dispatch }: any, payload: any) {
      return new Promise((resolve, reject) => {
        const sendData = new FormData();
        sendData.append('key', payload.key);
        if (payload.value) {
          sendData.append('value', payload.value);
        } else if (payload.file) {
          sendData.append('file', payload.file);
        }
        sendData.append('confirmed', payload.confirmed);

        HTTP.post(
          `api/detail/${state.editions.activeEditionId}/${state.company.company.id}`,
          sendData,
        )
          .then((response) => {
            dispatch('getCompanyData', state.company.company.id).then(() => {
              resolve(response);
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    sendSingleDetailST({ state, dispatch }: any, payload: any) {
      return new Promise((resolve, reject) => {
        const sendData = new FormData();
        sendData.append('key', payload.key);
        if (payload.value) {
          sendData.append('value', payload.value);
        } else if (payload.file) {
          sendData.append('file', payload.file);
        }
        sendData.append('confirmed', payload.confirmed);

        HTTP.post(
          `api/detail/${state.editions.activeEditionId}/${state.company.company.id}`,
          sendData,
        )
          .then((response) => {
            dispatch('getCompanyData', state.company.company.id).then(() => {
              resolve(response);
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    vuexLoaded: (state) => {
      return state.loaded;
    },
  },
});
