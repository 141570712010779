var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"app"}},[(_vm.headerVisible)?_c('app-header'):_vm._e(),(
      _vm.menuVisible &&
      _vm.userRole === 'administrator' &&
      _vm.companyData &&
      _vm.hasLoadedData
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"columns small-12"},[_c('strong',[_vm._v("Nazwa firmy:")]),_vm._v(" "+_vm._s(_vm.companyData.name)+", "),_c('strong',[_vm._v("pakiet:")]),_vm._v(" "+_vm._s(_vm.currentCompanyPackage.name)+" ")])]):_vm._e(),_c('div',{staticClass:"page-container"},[(_vm.menuVisible && _vm.hasLoadedData)?_c('navigation-panel'):_vm._e(),_c('main',{staticClass:"page-container__main"},[(_vm.loadView)?_c('router-view'):_vm._e()],1)],1),(_vm.sessionExpired && !_vm.excludedPage)?_c('div',{staticClass:"session-expired blue-bg"},[_c('div',[_vm._m(0),_vm._v(" Twoja sesja wygasła, zaloguj się ponownie. "),_c('router-link',{staticClass:"btn btn--white margin-bt-1",attrs:{"to":{ path: '/' }}},[_c('span',[_vm._v(" Zaloguj się ")])])],1)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"blue-bg__logo-container"},[_vm._v(" Panel Pracodawcy"),_c('br'),_c('img',{attrs:{"src":"/img/logo-white.svg","alt":"Logo Jobicon"}})])
}]

export { render, staticRenderFns }