
import { mapGetters } from "vuex";
import Vue from "vue";

export default Vue.extend({
  data(): any {
    return {
      headerVisible: false,
      menuVisible: false,
      needsCompanyData: false,
    };
  },
  computed: {
    ...mapGetters([
      "activeEditionDetails",
      "userRole",
      "vuexLoaded",
      "companyData",
      "currentCompanyPackage",
      "sessionExpired",
    ]),
    hasLoadedData(): any {
      return (
        this.vuexLoaded !== false &&
        this.vuexLoaded !== null &&
        this.activeEditionDetails
      );
    },
    loadView(): any {
      return this.needsCompanyData || this.activeEditionDetails;
    },
    excludedPage(): any {
      return ["login", "ResetPassword"].includes(this.$route.name);
    },
  },
  mounted() {
    this.headerVisible = this.$router.currentRoute.meta.headerVisible;
    this.menuVisible = this.$router.currentRoute.meta.menuVisible;
    this.needsCompanyData = !this.$router.currentRoute.meta.requiresCompany;

    window.addEventListener("focus", () => {
      this.$store.dispatch("checkSessionState");
    });
  },
  watch: {
    $route(to, from) {
      this.headerVisible = this.$router.currentRoute.meta.headerVisible;
      this.menuVisible = this.$router.currentRoute.meta.menuVisible;
      this.needsCompanyData = !this.$router.currentRoute.meta.requiresCompany;
    },
  },
});
