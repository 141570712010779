import axios from "axios";
import store from "../store";

export const HTTP = axios.create({
  baseURL: "https://api-panelpracodawcy.pracuj.pl/",
  headers: {
    Accept: "application/json",
  },
});

HTTP.interceptors.response.use(
  function (response) {
    if (response.statusText === "OK") {
      store.commit("SET_SESSION_EXPIRED", false);
    }
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.commit("SET_SESSION_EXPIRED", true);
      store.commit("LOGOUT");
    }
    return Promise.reject(error);
  }
);

HTTP.defaults.withCredentials = true;
