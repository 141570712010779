import Vue from "vue";
import Router, { Route } from "vue-router";
import Login from "./views/Account/Login.vue";
import { Dictionary } from "vue-router/types/router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "login",
      component: Login,
      meta: {
        headerVisible: false,
        menuVisible: false,
        authLevel: "guest",
        requiresAuth: false,
        requiresCompany: false,
      },
    },
    {
      path: "/admin/mfa",
      name: "mfa",
      component: () => import("./views/Admin/MFA.vue"),
      meta: {
        headerVisible: false,
        menuVisible: false,
        authLevel: "administrator",
        requiresAuth: true,
        requiresCompany: false,
      },
    },
    {
      path: "/ustawianie-hasla/:token",
      name: "ResetPassword",
      component: () => import("./views/Account/ResetPassword.vue"),
      meta: {
        headerVisible: false,
        menuVisible: false,
        authLevel: "guest",
        requiresAuth: false,
        requiresCompany: false,
      },
      props: true,
    },
    {
      path: "/brak-dostepu",
      name: "Unauthorized",
      component: () => import("./views/Account/Unauthorized.vue"),
      meta: {
        headerVisible: false,
        menuVisible: false,
        authLevel: "guest",
        requiresAuth: false,
        requiresCompany: false,
      },
      props: true,
    },
    {
      path: "/wybor-firmy",
      name: "ChooseActiveCompany",
      component: () => import("./views/Account/ChooseActiveCompany.vue"),
      meta: {
        headerVisible: false,
        menuVisible: false,
        authLevel: "client",
        requiresAuth: true,
        requiresCompany: false,
      },
    },
    {
      path: "/informacje-organizacyjne",
      name: "Informations",
      component: () => import("./views/Informational/Informations.vue"),
      meta: {
        headerVisible: true,
        menuVisible: true,
        authLevel: "client",
        requiresAuth: true,
        requiresCompany: true,
      },
    },
    {
      path: "/moj-pakiet",
      name: "Package",
      component: () => import("./views/Informational/Package.vue"),
      meta: {
        headerVisible: true,
        menuVisible: true,
        authLevel: "client",
        requiresAuth: true,
        requiresCompany: true,
      },
    },
    {
      path: "/kontakt",
      name: "Contact",
      component: () => import("./views/Informational/Contact.vue"),
      meta: {
        headerVisible: true,
        menuVisible: true,
        authLevel: "client",
        requiresAuth: true,
        requiresCompany: true,
      },
    },
    {
      path: "/dane-wystawcy",
      name: "ExhibitorDetails",
      component: () => import("./views/ClientForms/ExhibitorDetails.vue"),
      meta: {
        headerVisible: true,
        menuVisible: true,
        authLevel: "client",
        requiresAuth: true,
        requiresCompany: true,
      },
    },
    {
      path: "/stoisko-wystawcy",
      name: "ExhibitorBooth",
      component: () => import("./views/ClientForms/ExhibitorBooth.vue"),
      meta: {
        headerVisible: true,
        menuVisible: true,
        authLevel: "client",
        requiresAuth: true,
        requiresCompany: true,
      },
    },
    {
      path: "/wizytowka-w-wirtualnym-jobiconie/",
      name: "CatalogBusinessCard",
      component: () => import("./views/ClientForms/CatalogBusinessCard.vue"),
      props: true,
      meta: {
        headerVisible: true,
        menuVisible: true,
        authLevel: "client",
        requiresAuth: true,
        requiresCompany: true,
      },
    },
    {
      path: "/zbieranie-aplikacji",
      name: "Zbieranie aplikacji",
      component: () => import("./views/ClientForms/CollectingApplications.vue"),
      meta: {
        headerVisible: true,
        menuVisible: true,
        authLevel: "client",
        requiresAuth: true,
        requiresCompany: true,
      },
    },
    {
      path: "/moje-ogloszenia-na-wydarzeniu",
      name: "PersonalAds",
      component: () => import("./views/ClientForms/PersonalAds.vue"),
      meta: {
        headerVisible: true,
        menuVisible: true,
        authLevel: "client",
        requiresAuth: true,
        requiresCompany: true,
      },
    },
    {
      path: "/ogloszenia-promocyjne",
      name: "PromotionalAds",
      component: () => import("./views/ClientForms/PromotionalAds.vue"),
      meta: {
        headerVisible: true,
        menuVisible: true,
        authLevel: "client",
        requiresAuth: true,
        requiresCompany: true,
      },
    },
    {
      path: "/prelekcja",
      name: "Lectures",
      component: () => import("./views/ClientForms/Lectures.vue"),
      meta: {
        headerVisible: true,
        menuVisible: true,
        authLevel: "client",
        requiresAuth: true,
        requiresCompany: true,
      },
    },
    {
      path: "/admin",
      name: "Admin",
      component: () => import("./views/Admin/Index.vue"),
      meta: {
        headerVisible: false,
        menuVisible: false,
        authLevel: "administrator",
        requiresAuth: true,
        requiresCompany: false,
      },
    },
    {
      path: "/admin/firmy",
      name: "Companies",
      component: () => import("./views/Admin/Companies.vue"),
      meta: {
        headerVisible: true,
        menuVisible: false,
        adminPanel: true,
        authLevel: "administrator",
        requiresAuth: true,
        requiresCompany: false,
      },
    },
    {
      path: "/admin/uslugi",
      name: "Services",
      component: () => import("./views/Admin/Services.vue"),
      meta: {
        headerVisible: true,
        menuVisible: false,
        adminPanel: true,
        authLevel: "administrator",
        requiresAuth: true,
        requiresCompany: false,
      },
    },
    {
      path: "/admin/wiadomosci",
      name: "News",
      component: () => import("./views/Admin/News.vue"),
      meta: {
        headerVisible: true,
        menuVisible: false,
        adminPanel: true,
        authLevel: "administrator",
        requiresAuth: true,
        requiresCompany: false,
      },
    },
    {
      path: "/admin/pakiety",
      name: "Packages",
      component: () => import("./views/Admin/Packages.vue"),
      meta: {
        headerVisible: true,
        menuVisible: false,
        adminPanel: true,
        authLevel: "administrator",
        requiresAuth: true,
        requiresCompany: false,
      },
    },
    {
      path: "/admin/edycje",
      name: "Editions",
      component: () => import("./views/Admin/Editions.vue"),
      meta: {
        headerVisible: true,
        menuVisible: false,
        adminPanel: true,
        authLevel: "administrator",
        requiresAuth: true,
        requiresCompany: false,
      },
    },
    {
      path: "/admin/uzytkownicy",
      name: "Users",
      component: () => import("./views/Admin/Users.vue"),
      meta: {
        headerVisible: true,
        menuVisible: false,
        adminPanel: true,
        authLevel: "administrator",
        requiresAuth: true,
        requiresCompany: false,
      },
    },
    {
      path: "/admin/firmy/:id",
      name: "CompanyEdition",
      component: () => import("./views/Admin/CompanyEdition.vue"),
      props: true,
      meta: {
        headerVisible: true,
        menuVisible: false,
        adminPanel: true,
        authLevel: "administrator",
        requiresAuth: true,
        requiresCompany: false,
      },
    },
    {
      path: "/admin/firmy/:companyId/edycje/:editionId",
      name: "CompanyEditionDetails",
      component: () => import("./views/Admin/CompanyEditionDetails.vue"),
      props: parseNumericProps,
      meta: {
        headerVisible: true,
        menuVisible: false,
        adminPanel: true,
        authLevel: "administrator",
        requiresAuth: true,
        requiresCompany: false,
      },
    },
  ],
});

function parseNumericProps(route: Route): Dictionary<number> {
  const returnObj: Dictionary<number> = {};
  Object.keys(route.params).forEach((key) => {
    returnObj[key] = parseInt(route.params[key], 10);
  });

  return returnObj;
}
