<template>
  <header class="header">
    <div class="row align-middle expanded header__container">
      <div
        class="
          small-6 small-offset-1
          medium-4 medium-offset-0
          large-2
          medium-order-1
          columns
        "
      >
        <router-link :to="{ path: '/' }"
          ><img
            class="header__logo"
            src="/img/logo.svg"
            alt="JOBICON by pracuj.pl"
        /></router-link>
      </div>
      <div
        class="small-5 medium-4 large-5 medium-order-4 columns header__login"
      >
        <div class="header__login--inner">
          <p class="header__user">Zalogowany użytkownik: {{ name }}</p>
          <main-btn
            :small="true"
            v-on:click="logout"
            :content="'Wyloguj się'"
          />
        </div>
        <router-link
          v-if="userRole === 'administrator' && pageAuthLevel === 'client'"
          :to="{ path: `/admin` }"
        >
          <main-btn :small="true" :content="'Powrót do panelu admina'" />
        </router-link>
      </div>
      <div
        class="small-12 medium-4 large-5 medium-order-2 header__counter columns"
      >
        <counter-section
          class="margin-both-1"
          v-if="menuVisible && activeEditionDetails"
          :date="activeEditionDetails.end_date"
          :start-date="activeEditionDetails.start_date"
          :magenta="true"
        />
        <counter-section
          class="margin-both-1"
          text="Czas na uzupełnienie zakładki „Zbieranie aplikacji” oraz „Moje ogłoszenia w Wirtualnym JOBICONIE” jest wydłużony i upłynie za:"
          v-if="menuVisible && activeEditionDetails"
          :start-date="activeEditionDetails.start_date"
          :date="activeEditionDetails.adverts_end_date"
        />
      </div>
    </div>
    <div class="row expanded">
      <div class="small-12 columns">
        <admin-bar v-if="adminPanel" />
        <editions-bar v-else />
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      headerVisible: false,
      menuVisible: false,
      pageAuthLevel: "",
      adminPanel: false,
    };
  },
  computed: {
    ...mapGetters(["activeEditionDetails", "userRole", "vuexLoaded"]),
    hasLoadedData() {
      return (
        this.vuexLoaded !== false &&
        this.vuexLoaded !== null &&
        this.activeEditionDetails
      );
    },
    name() {
      return this.$store.state.auth.user.email;
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
  mounted() {
    this.adminPanel = this.$router.currentRoute.meta.adminPanel;
    this.headerVisible = this.$router.currentRoute.meta.headerVisible;
    this.menuVisible = this.$router.currentRoute.meta.menuVisible;
    this.pageAuthLevel = this.$router.currentRoute.meta.authLevel;
  },
  watch: {
    $route(to, from) {
      this.adminPanel = this.$router.currentRoute.meta.adminPanel;
      this.headerVisible = this.$router.currentRoute.meta.headerVisible;
      this.menuVisible = this.$router.currentRoute.meta.menuVisible;
      this.pageAuthLevel = this.$router.currentRoute.meta.authLevel;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  z-index: 9999;
  padding-bottom: 20px;
  background: #fff;

  &__logo {
    margin-left: 10px;
    @include desktop {
      margin-left: 0;
    }
  }

  &__container {
    min-height: 150px;
  }

  &__counter {
    margin-top: 20px;
    @include desktop {
      margin-top: 0;
    }
  }

  &__login {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;

    &--inner {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 10px;
      @include desktop {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  &__user {
    color: $gray-color;
    display: inline-block;
    text-align: right;
    font-size: 12px;
    margin-bottom: 0;
    @include desktop {
      margin-right: 20px;
      font-size: 14px;
    }
  }
}
</style>
