import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import Header from "./components/Menu/Header.vue";
import VueRouter from "vue-router";
import "promise-polyfill/src/polyfill";
import "./scss/main.scss";
import { sync } from "vuex-router-sync";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import VueCookies from "vue-cookies";

Vue.config.productionTip = false;

Vue.component("app-header", Header);

Vue.component("main-btn", (resolve: any) => {
  require(["@/components/Button"], resolve);
});

Vue.component("editions-bar", (resolve: any) => {
  require(["@/components/Menu/EditionsBar"], resolve);
});

Vue.component("admin-bar", (resolve: any) => {
  require(["@/components/Menu/AdminBar"], resolve);
});

Vue.component("v-input", (resolve: any) => {
  require(["@/components/Form/VInput"], resolve);
});

Vue.component("navigation-panel", (resolve: any) => {
  require(["@/components/Menu/Navigation"], resolve);
});

Vue.component("counter-section", (resolve: any) => {
  require(["@/components/Counter"], resolve);
});

Vue.component("news-section", (resolve: any) => {
  require(["@/components/News"], resolve);
});

Vue.component("services-section", (resolve: any) => {
  require(["@/components/Services"], resolve);
});

Vue.component("v-upload", (resolve: any) => {
  require(["@/components/Form/VUpload"], resolve);
});

Vue.component("visualisation", (resolve: any) => {
  require(["@/components/Visualisation"], resolve);
});

Vue.component("v-radio-button", (resolve: any) => {
  require(["@/components/Form/VRadioButton"], resolve);
});

Vue.component("v-checkbox-slider", (resolve: any) => {
  require(["@/components/Form/VCheckboxSlider"], resolve);
});

Vue.component("v-select", (resolve: any) => {
  require(["@/components/Form/VSelect"], resolve);
});

Vue.component("v-textarea", (resolve: any) => {
  require(["@/components/Form/VTextArea"], resolve);
});

Vue.component("tags-select", (resolve: any) => {
  require(["@/components/Form/TagsSelect"], resolve);
});

Vue.component("table-data", (resolve: any) => {
  require(["@/components/Admin/TableData"], resolve);
});

Vue.component("single-speaker", (resolve: any) => {
  require(["@/components/SingleSpeaker"], resolve);
});

Vue.component("pre-view", (resolve: any) => {
  require(["@/components/Preview"], resolve);
});

Vue.component("search-bar", (resolve: any) => {
  require(["@/components/Admin/Search"], resolve);
});

Vue.component("edition-form", (resolve: any) => {
  require(["@/components/Admin/EditionForm"], resolve);
});

Vue.component("download-data", (resolve: any) => {
  require(["@/components/DownloadData"], resolve);
});

Vue.use(VueRouter);
Vue.use(VueCookies);
// Vue.use(xss);
sync(store, router);

Sentry.init({
  Vue,
  dsn: "https://39d56f8a977e4160aa1b163f37bde720@o271904.ingest.sentry.io/4504326683230208",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["panelpracodawcy.pracuj.pl", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
const token = Vue.$cookies.get("XSRF-TOKEN");
let activeCompany = localStorage.getItem("active-company");

const authLevelValue: any = {
  guest: 0,
  client: 1,
  distributor: 2,
  administrator: 3,
};

router.beforeEach((to, from, next) => {
  const twoFactor = store.getters.twoFactor;
  let userRole = store.getters.userRole;
  if (userRole === null) {
    userRole = "guest";
  }

  // if token is expired or is invalid or account/company is deleted it needs to be reseted
  const activeCompanyItem = localStorage.getItem("active-company");
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresCompany = to.matched.some(
    (record) => record.meta.requiresCompany
  );
  let authLevel = null;
  if (to.meta) {
    authLevel = authLevelValue[to.meta.authLevel];
  }
  const userLevel = authLevelValue[userRole];

  if (userRole === "administrator" && token && !twoFactor && requiresAuth) {
    if (to.name === "login" || to.name !== "mfa") {
      next("/admin/mfa");
      return;
    }
  }

  if (userLevel < authLevel) {
    if (requiresAuth && !token) {
      next("/");
      return;
    }
    next("/brak-dostepu");
    return;
  }

  if (requiresCompany && !activeCompanyItem) {
    next("/wybor-firmy");
    return;
  }

  next();
});

if (token) {
  store.dispatch("saveUserData").then(() => {
    new Vue({
      router,
      store,
      render: (h: any) => h(App),
    }).$mount("#app");

    if (activeCompany) {
      store.dispatch("getCompanyData", activeCompany).catch((error: any) => {
        activeCompany = null;
        localStorage.removeItem("active-company");
      });
    }
  });
} else {
  new Vue({
    router,
    store,
    render: (h: any) => h(App),
  }).$mount("#app");
}
